<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <img alt="Logo" :src="logo" width="100" />
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
    <div class="m-4"></div>
    <b-badge variant="primary" class="mt-2" v-if="badge && showDataDebug">{{ $gettext(badge) }}</b-badge>
    <p class="text-primary mt-2">{{ description }}</p>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
import statics from "@/core/statics/statics.js";

export default {
  name: "Loader",
  props: {
    logo: String,
    spinnerClass: String,
    badge: String,
    description: String,
  },
  data() {
    return {
      // Preferences
      showDataDebug: statics.showDataDebug,
    };
  },
};
</script>
