<template>
  <div class="d-flex flex-column flex-root">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
      :badge="$gettext('Autologin')"
      :description="$gettext('Please wait, you are going to be redirected to the dashboard...')"
    ></Loader>

    <b-button
      variant="primary"
      :to="{
        name: 'route.home',
      }"
      size="lg"
      class="fixed-top"
      v-if="isAuthenticated == true && isOverriddenBaseURL == true"
      ><translate>Click here to access to dashboard now</translate></b-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import storage from "@/core/services/storage.service";
import Loader from "@/view/layout/content/Loader";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

export default {
  name: "autologin",
  components: {
    Loader,
  },
  beforeCreate() {
    console.log("AutoLogin set authentification");
    storage.authenticated(true);
    storage.setDisconnectInformations({ route_name: "disconnected" });

    // Load mode
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    setTimeout(() => {
      // Redirect after time
      this.$router.push({ name: "route.home" });

      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

      // window.location = "./";
    }, 2000);
  },
  mounted() {
    console.info("AutoLogin.vue");
  },
  methods: {
    // Global check auth
    // checkAuthentification(auth, config) {
    //   console.log("[AUTH] checkAuthentification:: + config", auth, config);
    //   console.info("Autologin.vue::Watch::[AUTH] [CONFIG]", "this.isOverriddenBaseURL", this.isOverriddenBaseURL, "this.isAuthenticated", this.isAuthenticated);
    //   if (!auth || !config) {
    //     console.info("Autologin.vue::User is not authenticated or no config is getted -----------------------", this.$route, this.$router.currentRoute.name);
    //     console.info("@Wilhem App.vue:: DO WE NEED ? route to login here ? ");
    //     //if (this.$router.currentRoute.name !== "login" && this.$router.currentRoute.name !== null) this.$router.push({ name: "login" });
    //   } else {
    //     console.info("Autologin.vue::User is authenticated and config is getted -----------------------", this.$route, this.$router.currentRoute.name);
    //     // Then delay page loading removing
    //     setTimeout(() => {
    //       // Redirect after time
    //       // this.$router.push({ name: "route.dashboard" });
    //     }, 2000);
    //   }
    // },
  },
  // watch: {
  //   isAuthenticated: {
  //     immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
  //     handler: function (n) {
  //       console.log("Autologin.vue::Watch::isAuthenticated");
  //       this.checkAuthentification(n, this.isOverriddenBaseURL);
  //     },
  //   },
  //   isOverriddenBaseURL: {
  //     immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
  //     handler: function (n) {
  //       console.log("Autologin.vue::Watch::isOverriddenBaseURL");
  //       this.checkAuthentification(this.isAuthenticated, n);
  //     },
  //   },
  // },
  computed: {
    ...mapGetters(["isAuthenticated", "isOverriddenBaseURL", "layoutConfig"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/error/bgerror.jpg";
    },

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>
